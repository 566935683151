<div class="multi-select">
    @for (option of multiSelectOptions; track option.title; let i = $index) {
        <div
            class="multi-select-button {{ option.backgroundColor }} {{
                multiSelectOptions[i].selected ? 'selected' : ''
            }}"
            (click)="selectSpecialNeed(i)"
            [id]="option.value"
        >
            <img [src]="option.image" [alt]="option.title + ' illustration'" />
            <p class="text" [ngStyle]="{ color: 'var(--ion-color-' + option.backgroundColor + '-contrast)' }">
                {{ option.title }}
            </p>
            @if (multiSelectOptions[i].selected) {
                <ion-icon [name]="'checkmark-sharp'"></ion-icon>
            }
        </div>
    }
</div>
